// src/components/Contact.js
import React, { useState } from 'react';
import contactus from "../logos/contactus.jpeg";
import sicon from "../logos/Salesforceicon.png";
import "./services.css";
import {useParams,useLocation, replace} from "react-router-dom";



function Contact() {
  let params = useLocation();
  console.log('id ',params.hash);
  let id=params.hash.replace('#','');
  if(!id){
    id='sales';
  }
    
    const [activeTopic, setActiveTopic] = useState(id);
  const topics = [
    { id: 'sales', title: 'Sales Cloud', content: `Salesforce Sales Cloud is a cloud-based CRM platform designed to help sales teams manage leads, opportunities, and customer relationships more effectively. It offers automation of repetitive tasks, real-time analytics, and AI-powered insights with Salesforce Einstein to help sales reps prioritize efforts and close deals faster. Sales Cloud integrates seamlessly with other Salesforce products, providing a unified view of the customer. It also supports mobile access, enabling sales teams to work from anywhere. With customizable workflows, robust reporting, and enhanced collaboration tools, Sales Cloud enables businesses to streamline sales processes and boost overall performance, making it ideal for organizations of all sizes.` },
    { id: 'service', title: 'Service Cloud', content: `Salesforce Service Cloud is a customer service platform that enhances support teams' efficiency and customer satisfaction. It enables agents to provide personalized, omnichannel support, including email, chat, social media, and phone, all from one unified interface. With AI-powered tools like Einstein, Service Cloud offers automated case routing, intelligent recommendations, and predictive insights to resolve issues faster. The platform also integrates seamlessly with other Salesforce products, providing a 360-degree view of the customer. Benefits include improved team collaboration, faster issue resolution, enhanced customer experiences, and increased productivity, making it ideal for organizations aiming to deliver exceptional customer service.` },
    { id: 'health', title: 'Health Cloud', content: `Salesforce Health Cloud is a patient management platform that enhances healthcare delivery by providing a unified view of patient data. It enables healthcare providers to securely manage patient relationships, streamline care coordination, and improve patient outcomes. Benefits include personalized care through comprehensive patient profiles, real-time collaboration among care teams, and improved patient engagement via secure communication channels. With advanced analytics, Health Cloud helps healthcare organizations optimize operations and make data-driven decisions. The platform also integrates with various healthcare systems, ensuring seamless data flow. Overall, Salesforce Health Cloud enhances efficiency, patient satisfaction, and the quality of care provided.` },
    { id: 'data', title: 'Data Cloud', content: `Salesforce Data Cloud is a comprehensive data management platform that enables organizations to unify, analyze, and act on data in real time. It integrates data from multiple sources, providing a single view of customer information, helping businesses deliver personalized experiences. Benefits include enhanced data accessibility, improved decision-making through AI-powered insights, and seamless integration with other Salesforce products. Data Cloud also ensures data security and compliance, allowing businesses to manage sensitive information safely. By enabling real-time analytics and automation, it empowers organizations to optimize operations, drive growth, and improve customer engagement, making it an essential tool for data-driven decision-making.` },
    { id: 'experience', title: 'Experience Cloud', content: `Salesforce Experience Cloud is a platform that enables businesses to create personalized, branded digital experiences for customers, partners, and employees. It offers seamless integration with Salesforce CRM, providing a 360-degree view of users' data for better engagement. Benefits include enhanced collaboration through community forums, personalized content, and self-service options that empower customers to find solutions independently. It also supports automated workflows, improving efficiency, and streamlining communication. With customizable templates and advanced analytics, businesses can optimize experiences, drive customer loyalty, and improve satisfaction. Experience Cloud strengthens relationships, increases productivity, and enables organizations to deliver tailored, responsive solutions.` },
    { id: 'analytics', title: 'Analytics Cloud', content: `Salesforce Analytics Cloud, also known as Tableau CRM, is a powerful business intelligence platform that enables organizations to turn data into actionable insights. Benefits include real-time data visualization, customizable dashboards, and AI-powered analytics through Salesforce Einstein. It empowers users to make data-driven decisions by offering detailed reports and predictive insights. Analytics Cloud integrates seamlessly with Salesforce data, providing a unified view across all departments. With self-service analytics, users can explore data without relying on IT, improving efficiency and decision-making. Additionally, it supports collaboration by sharing insights across teams, helping businesses optimize operations and drive growth through informed strategies.` },
    { id: 'manufacturing', title: 'Manufacturing Cloud', content: `Salesforce Manufacturing Cloud is a specialized solution designed for manufacturers to enhance operational efficiency and customer relationships. Benefits include better demand forecasting and real-time visibility into the supply chain, enabling manufacturers to optimize production schedules and reduce costs. It provides a unified view of customer data, allowing manufacturers to offer personalized service and improve customer satisfaction. The platform also integrates seamlessly with other Salesforce products, streamlining processes across sales, service, and operations. With advanced analytics and AI-powered insights, Manufacturing Cloud helps businesses make data-driven decisions, enhance collaboration, and drive growth while improving overall productivity and profitability.`},
  ];

  const handleClick = (topicId) => {
    setActiveTopic(topicId);
  };

    

  return (
    <div>
        <header>
        <div class="textoverimage">SERVICES</div>
          <img src={contactus} style={{height:"100%",width:"100%"}}></img>
        </header>

        {/* <div class="circle-container">
            <img src={sicon} class="tileservice"></img>
            <img src={sicon} class="tileservice"></img>
            <img src={sicon} class="tileservice"></img>
            <img src={sicon} class="tileservice"></img>
            <img src={sicon} class="tileservice"></img>
            <img src={sicon} class="tileservice"></img>
        </div> */}

<div className="topiccontainer" id="topiccontainer">
      {/* Left Panel (Topics) */}
      <div className="left-panel">
        <ul className="topic-list">
          {topics.map((topic) => (
            <li key={topic.id} onClick={() => handleClick(topic.id)}>
              {topic.title}
            </li>
          ))}
        </ul>
      </div>

      <div className="right-panel">
        {topics.map((topic) => (
          <div
            key={topic.id}
            className={`content-panel ${activeTopic === topic.id ? 'active' : ''}`}
          >
            <h2>{topic.title}</h2>
            <div class="contentcontainer">
              <div class="text">{topic.content}</div>
              <div class="iframe" style={{ display: "flex", justifyContent: "center", alignItems: "right", backgroundColor: "#f0f0f0" }}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/-Clj8w2CodY" 
                  title="YouTube video player"
                  frameBorder="0"
                  
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <p></p>
          </div>
        ))}
      </div>
    </div>

   

</div>
  );
}

export default Contact;
