
import React, { useState } from 'react';
import twitter from "../logos/twitter.jpg";
import linkedin from "../logos/linkedin.png";
import insta from "../logos/insts.jpg";

function Header() {
  const [message, setMessage] = useState('');



  return (
    <div>
      <div class="top-strip">
            <a href="mailto:example@example.com" class="mailto-link">Email us <i>info@pramitra.com</i></a>
            <div class="social-icons">
                
                <a href="http://linkedin.com/company/pramitra-software-services" target="_blank" title="Facebook">
                    <img src={linkedin} alt="LinkedIn" class="socialLogo"/>
                </a>
                {/* <a href="https://www.twitter.com" target="_blank" title="Twitter">
                <img src={twitter} alt="Facebook" class="socialLogo"/>
                </a> */}
                <a href="https://www.instagram.com/pramitrasoftware/" target="_blank" title="Instagram">
                <img src={insta} alt="Instagram" class="socialLogo"/>
                </a>
            </div>
        </div>
    </div>
  );
}

export default Header;
