
import React, { useState } from 'react';
import twitter from "../logos/twitter.jpg";
import facebook from "../logos/facebook.jpg";
import insta from "../logos/insts.jpg";
import logo from "../logos/newlogo.jpeg";
import { Router, Routes, Route, Link } from "react-router-dom";
import Home from '../files/homee.js';
import Contact from '../files/contact';
import About from '../files/about';
import Services from '../files/services.js';
import "./navigation.css";
import Unmanaged from './unmanaged.js';

function Navigation() {
  const [message, setMessage] = useState('');
  const links = [
    {"label":"Home","to":"/"}, 
    {"label":"Services","to":"/services"}, 
    {"label":"About","to":"/about"}, 
    {"label":"Contact","to":"/contact"}, 
    {"label":"Blog","to":"/contact"}, 
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index); 
  };


  

  return (
    <div>

    <div class="row">
        <div class="column">
        <div class="logo">
            <img class="logo" src={logo} alt="Pramitra Software Services"></img>
            {/* Pramitra <span style={{paddingLeft:"8px"}}> Software Services</span> */}
        </div>
        </div>
        <div class="column">
            <div style={{float:'right',marginRight: '112px'}}>
              <nav>
                  <ul>
                    {links.map((link, index) => (
                      <li key={index}>
                        <Link to={link.to} className={`navlink ${activeIndex === index ? 'active' : ''}`}
                          onClick={() => handleClick(index)}
                        >{link.label}</Link>
                      </li>
                    ))}
                  </ul>
                </nav>
            </div>
        </div>
    </div>
    <div style={{ paddingTop: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/unmanaged" element={<Unmanaged />} />
          </Routes>
        </div>

      
    </div>
  );
}

export default Navigation;
