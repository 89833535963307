// src/components/Home.js
import React,  { useState, useEffect }  from 'react';
import "./home.css";
import HomeService from '../files/homeservices.js';
import HomeCarousal from '../files/homeCarousal.js';
import image from "../images.jpg"
import { useNavigate } from 'react-router-dom';
import unmanaged from "../logos/unmanaged.gif";
import fp1 from "../logos/vershacare.png";
import fp2 from "../logos/investotalk.png";
import fp3 from "../logos/Technocon.jpeg";
import ai from "../logos/ai.jpeg";
import customer from "../logos/customer.jpeg";
import data from "../logos/data.jpeg";
import cloud from "../logos/cloud.jpeg";
import aicloud from "../logos/clouds/aicloud.jpg";
import salescloud from "../logos/clouds/salescloud.avif";
import servicecloud from "../logos/clouds/servicecloud.jpg";
import mancloud from "../logos/clouds/manufacturingcloud.png";
import datacloud from "../logos/clouds/datacloud.jpg";
import healthcloud from "../logos/clouds/health cloud.png";
import expcloud from "../logos/clouds/experinececloud.jpeg";
import analyticscloud from "../logos/clouds/analyticscloud.png";

function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Array of cards content
  const cards = [
    {
      title: 'Advantage of Unmanaged Package',
      src: unmanaged
    },
    {
      title: 'Salesforce Consulting',
      src:ai
    },
    {
      title: 'Salesforce Development',
      src:customer
    },
    {
      title: 'Salesforce Support',
       src: data
    }
  ];

  const infocard1 = [{
    title: 'Support',
    description: 'Our dedicated support team is available to assist you with any Salesforce-related issues, ensuring smooth operation at all times.',
    image: 'https://via.placeholder.com/1000x200/2B4D67/ffffff?text=Salesforce+Support'
  }];

  const infocard3 = [{
    title:'data cloud',
    img:datacloud
  },
  {
    title:'Sales cloud',
    img:salescloud
  },
  {
    title:'Health cloud',
    img:healthcloud
  },
  {
    title:'Manufacturing cloud',
    img:mancloud
  }
]

const infocard4 = [
{
  title:'Service cloud',
  img:servicecloud
},
{
  title:'Experience cloud',
  img:expcloud
},
{
  title:'Analytics cloud',
  img:analyticscloud
},
{
  title:'Artificial Intelligence',
  img:aicloud
}
]

  const infocard2 = [{
    title: 'Data Migration',
    text:'We Specialize in',
      points: [
        {
          title:'Seamless Transition',
          desc:'Ensuring zero data loss and minimal downtime through migration'
        },
        {
          title:'Data Integrity',
          desc:'Maintaining accuracy and consistency throughout the migration.'
        },
        {
          title:'Custom Solutions',
          desc:'Addressing your specific requirements with precision.'
        }

      ]
    },
    {
      title: 'Support',
      text:'We Specialize in',
        points: [
          {
            title:'End-to-End Support',
            desc:'From initial implementation to seamless project execution.'
          },
          {
            title:'Enhancements',
            desc:'Tailored updates to meet evolving business needs.'
          },
          {
            title:'Proactive Problem-Solving:',
            desc:'Minimizing downtime and maximizing performance with optimized solution'
          }
  
        ]
      },
      {
        title: 'Development',
        text:'We Specialize in',
          points: [
            {
              title:'Custom App Development:',
              desc:'Building powerful apps tailored to your Business.'
            },
            {
              title:'Scalable Solutions',
              desc:'Crafting flexible designs to grow with your business'
            },
            {
              title:'Business Process Automation',
              desc:'Streamlining operations for greater efficiency'
            }
    
          ]
        }
        ,
        {
          title: 'Managed Services',
          text:'We Specialize in',
            points: [
              {
                title:'Seamless Transition',
                desc:'Ensuring zero data loss and minimal downtime.'
              },
              {
                title:'Data Integrity',
                desc:'Maintaining accuracy and consistency throughout the migration.'
              },
              {
                title:'Custom Solutions',
                desc:'Addressing your specific requirements with precision.'
              }
      
            ]
          }
  ];

  function navigatToContact(event){
    window.location.href='/contact';
  }

  // Function to update the card display
  const updateCardDisplay = (index,fromcard) => {
    if(!fromcard){
      setCurrentIndex(index,fromcard);
    }else{
      setCurrentIndex(()=>index);
      //clearInterval(interval);
    }
    
  };

  const navigate = useNavigate();
  const navigateToAbout = () => {
    console.log('hi');
      window.location.href='/unmanaged'
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, 4000); 

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [cards.length]);
  

  return (
<div className='outercontainer1'>
  <title>Software Service Homepage</title>
  

  <div className="homecontainer">
      {/* <div className="card-container" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {cards.map((card, index) => (
          <div key={index} className="card">
             <div class="cardcontainer2">
              <img onClick={navigateToAbout}  src={card.src} alt="Placeholder Image"></img>
            </div>
          </div>
        ))}
      </div>
      <div className="dots">
        {cards.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentIndex === index ? 'active' : ''}`}
            onClick={() => updateCardDisplay(index,true)}
          />
        ))}
      </div> */}
      <HomeCarousal></HomeCarousal>

      <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>

    </div>

    <section class="features2">
    <div class="animated-text">We are specialized in </div>
    </section>

    <HomeService></HomeService>

    {/* <section class="features">
        {infocard3.map((card, index) => (
          <div key={index} class="feature-card">
            <h3 background={card.img}>{card.title}</h3>
            <img style={{width:"90%",height:"80%",backgroundSize:"cover"}} src={card.img} alt="image"></img>
          </div>
        ))}
      </section>
      <section class="features">
        {infocard4.map((card, index) => (
          <div key={index} class="feature-card">
            <h3 background={card.img}>{card.title}</h3>
            <img style={{width:"90%",height:"80%",backgroundSize:"cover"}} src={card.img} alt="image"></img>
          </div>
        ))}
      </section> */}

 
    <section class="features3">
    <div class="animated-text">You are Looking for...?</div>
    </section>
    <a href="/contact" class="buttonmore">
                <span>View All &#11162;</span>
            </a>
  
  <section class="features">
        {infocard2.map((card, index) => (
          <div key={index} class="feature-card">
            
            <ul class="ulist">
            {/* {card.points.map((point,index2)=>(
              <li key={index2}>
                <b>{point.title}</b>: {point.desc}
              </li>
            ))} */}
            <h3>{card.title}</h3>
            <img onClick={navigatToContact} src={cloud} style={{width:'230px'}} ></img>
            </ul>
            {/* <a href="/contact">
            <button className="gradient-button" >
              Know More
            </button>
            </a> */}
          </div>
        ))}
      </section>


    <section class="features3">
      <div class="animated-text">Book 2 hours Complementory Consultation</div>
    </section>

    <div class="outer-contact-container">
    <div class="contactform-container">
        <h2>Talk to our experts</h2>
        <form>
            <div class="contactform-group">
                <label for="name">Name</label>
                <input type="text" id="name" name="name" placeholder="Enter your name" required/>
            </div>
            <div class="contactform-group">
                <label for="company">Company Name</label>
                <input type="text" id="company" name="company" placeholder="Enter your company name"/>
            </div>
            <div class="contactform-group">
                <label for="mobile">Mobile Number</label>
                <input type="tel" id="mobile" name="mobile" placeholder="Enter your mobile number" required/>
            </div>
            <div class="contactform-group">
                <label for="email">Email</label>
                <input type="email" id="email" name="email" placeholder="Enter your email" required/>
            </div>
            <div class="contactform-group">
                <label for="time">Preferred Time</label>
                <input type="datetime-local" id="time" name="time" required/>
            </div>
            <div class="contactform-group">
                <label for="message">Message</label>
                <textarea id="message" name="message" placeholder="Enter your message"></textarea>
            </div>
            <button type="submit" class="submit-btn1">Schedule</button>
            
        </form>
</div>
</div>

<section class="features3">
      <div class="animated-text">Our trusted partners</div>
    </section>
  
    <div class="outer-contact-container">
      <div class="image-container">
          <div>
              <img src={fp1} alt="Image 1"/>
          </div>
          <div>
              <img src={fp2} alt="Image 2"/>
          </div>
          <div>
              <img src={fp3} alt="Image 3"/>
          </div>
      </div>
    </div>




    </div>
  );
}

export default Home;
