
import React,  { useState, useEffect }  from 'react';
import "./homeservice.css";
import salescloud from "../logos/services/Sales Cloud Front.png";
import mancloud from "../logos/services/Manufacturing Cloud Front.png";
import datacloudf from "../logos/services/Data Cloud Front.png";
import healthcloud from "../logos/services/Health Cloud Front.png";
import salescloudb from "../logos/services/Sales Cloud Back.jpg";
import mancloudb from "../logos/services/Manufacturing Cloud Back.jpg";
import datacloudb from "../logos/services/Data Cloud Back.jpg";
import healthcloudb from "../logos/services/Health Cloud Back.jpg";
import expcloud from "../logos/clouds/experinececloud.jpeg";
import analyticscloud from "../logos/clouds/analyticscloud.png";

const infocard3 = [{
    title:'Data cloud',
    name:'data',
    img:datacloudf,
    imgb:datacloudb,
    line1:'Boundless Data Integration',
    line2:'Data Collaboration'
  },
  {
    title:'Sales cloud',
    img:salescloud,
    imgb:salescloudb,
    name:'sales',
    line1:'Customer Management',
    line2:'Accelerate Sales Growth'
    
  },
  {
    title:'Health cloud',
    img:healthcloud,
    imgb:healthcloudb,
    name:'health',
    line1:'Optimize Patient Care',
    line2:'Streamline Health Data'
  },
  {
    title:'Manufacturing cloud',
    img:mancloud,
    imgb:mancloudb,
    name:'manufacturing',
    line1:'Streamline Supply Chain',
    line2:'Optimize Production Processes'
  }
]

function HomeService() {

    function navigate(event){
        window.location.href='/services#'+event.target.name;
    }
    function navigateToService(event){
        alert('hello');
        window.location.href='/services';
    }

    return (
        <div>
            
                <a class="buttonmore" href="/services">View All &#11162;</a>
            
            <div class="servicecontainer" id="wordContainer">
                {infocard3.map((card, index) => (
                    <div class="servicetile">
                        <div class="tile-inner">
                            <div class="tile-front">
                                 <img style={{width:"100%",height:"100%",backgroundSize:"cover"}} src={card.img} alt="image"></img> 
                            </div>
                            <div class="tile-back">
                                <div style={{padding:'8px',top:'0px',position:'relative',textAlign:'center',display:'block'}}>
                                    <img style={{width:"100%",height:"100%",backgroundSize:"cover"}} src={card.imgb} alt="image"></img> 
                                    <br></br>
                                    <div class="pointcontainer">
                                        <div class="circle-container1">
                                            <div class="circle-item1">
                                                
                                                <div class="text1">- {card.line1}</div>
                                            </div>
                                            <div class="circle-item1">
                                                
                                                <div class="text1">- {card.line2}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="know-more-button" onClick={navigate} name={card.name}>Know more</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <br></br>
            {/* <div class="servicecontainer" id="wordContainer">
                {infocard3.map((card, index) => (
                    <div class="servicetile">
                        <div class="tile-inner">
                            <div class="tile-front">W</div>
                            <div class="tile-back">Welcome</div>
                        </div>
                    </div>
                ))}
            </div> */}
        </div>
    );
}

export default HomeService;