
import React, { useEffect, useState } from 'react';
import "./unmanaged.css";
import unmanaged from "../logos/building.jpg";

function Unmanaged() {
    const [visibleTiles, setVisibleTiles] = useState([]);

    // Function to check if a tile is in the viewport
    const checkTilesInView = () => {
      const windowHeight = window.innerHeight;
      const tiles = document.querySelectorAll('.tile');
      tiles.forEach((tile, index) => {
        const rect = tile.getBoundingClientRect();
        if (rect.top < windowHeight - 150) { 
          if (!visibleTiles.includes(index)) {
            setVisibleTiles((prev) => [...prev, index]);
          }
        }
      });
    };

useEffect(() => {
    window.addEventListener('scroll', checkTilesInView);
    checkTilesInView(); // Check if any tiles are already in view on load

    return () => window.removeEventListener('scroll', checkTilesInView); // Clean up on unmount
  }, [visibleTiles]);

  return (
    <div>
`     <div className="card-container">
          <div className="card">
             <div class="cardcontainer1" >
              <img  src={unmanaged} alt="Placeholder Image"></img>
            </div>
          </div>
        
      </div>

      

      <div className="tile-container">
        {['Tile 1', 'Tile 2', 'Tile 3', 'Tile 4', 'Tile 5', 'Tile 6'].map((tile, index) => (
          <div
            key={index}
            className={`tile ${visibleTiles.includes(index) ? 'visible' : ''}`}
          >
            <h3>{tile}</h3>
            <p>This is some information about {tile}. It gives users insight into the content of the tile.</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Unmanaged;
