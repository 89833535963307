import React, { useState, useEffect } from 'react';
import './homeCarousal.css';
import unmanaged from "../logos/unmanaged.jpg";
import ai from "../logos/ai.jpeg";
import customer from "../logos/customer.jpeg";
import data from "../logos/data.jpeg";
import divback from "../logos/backgrounddiv.png";

const content = [
  { primary: unmanaged, topic: 'Unmanaged Package',point1:'Leverage Pre Built Templates',point2:'Save Sprint 0',point3:'Complete Ownership ' },
  { primary: ai, topic: 'Data Cloud',point1:'Connect Multiple Data Sources ',point2:'Refine Data with Data Cloud',point3:'Generate Insights' },
  { primary: customer, topic: 'Sales Cloud',point1:'Meet Revenue Targets', point2:'Increase Customer Acquisition',point3:'Market Expansion' },
  { primary: data, topic: 'Artificial Intelligence' ,point1:'Leverage Agentforce ',point2:'Customize AI to work for you',point3:'See furture trends with AI'},
];

function navigate(event){
  window.location.href='/contact'+event.target.name;
}

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
      }, 1000);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="primary">
      
        <img className={`content-wrapper ${animate ? 'slide-left' : 'slide-right'}`} style={{height:'100%',width:'100%',borderRadius: '8px'}} src={content[currentIndex].primary} alt="Images"></img>
      
      <div className={`secondary ${animate ? 'slide-left' : 'slide-right'}`}>
      {/* <img className={`content-wrapper ${animate ? 'slide-left' : 'slide-right'}`} style={{height:'100%',width:'100%',borderRadius: '8px'}} src={content[currentIndex].secondary} alt="Images"></img> */}

      
      <ul class="clist-container">
        <h1 style={{marginLeft:'50px'}}>{content[currentIndex].topic}</h1>
        <li class="clist-item">
            <div class="ccircle">+</div>
            <div class="ctext">{content[currentIndex].point1}</div>
        </li>
        <li class="clist-item">
            <div class="ccircle">+</div>
            <div class="ctext">{content[currentIndex].point2}</div>
        </li>
        <li class="clist-item">
            <div class="ccircle">+</div>
            <div class="ctext">{content[currentIndex].point3}</div>
        </li>
    </ul>
    
        
      </div>
      <button class="know-more-button hover" onClick={navigate} style={{left:'77%',top:'71%',marginTop:'37px',zIndex:'100'}}>Know more</button>
    </div>
  );
};

export default Carousel;
